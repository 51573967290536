<template>
  <b-modal :visible="visible"
           size="lg"
           hide-footer
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <ResultView :route-params="props.params"
                :result-info="{}"
                view-type="modal"
                :teachers-student-id="studentId"
    />
  </b-modal>
</template>
<script setup>
import { BModal } from 'bootstrap-vue'
import { ref } from 'vue'
import ResultView from '@/views/student/problem/compoments/Result.vue'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  studentId: {
    type: Number,
    required: true,
  },
})
const visible = ref(true)
</script>
