<template>
  <b-row>
    <b-col md="12">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >

        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'lessonName'">
            <span
              v-if="isHighLevelUser()"
              style="position: absolute;z-index: 9;left:-1px"
            >
              <feather-icon
                role="button"
                icon="InfoIcon"
                class="text-warning"
                @click="lessonIdToShowDetail = props.row.lessonId"
              />
            </span>
            <h4 v-if="props.row.completed_at">
              <a href.prevent=""
                 class="text-primary"
                 @click="openCompleted(props.row)"
              >
                {{ props.row.lessonName }}
              </a>
              <img
                v-if="props.row.is_first_done"
                class="first-badge ml-0"
                :src="require(`@/assets/images/${firstLessonAchieverBadge}`)"
                title="First Lesson Achiever"
              >
            </h4>
            <h4 v-else>
              <b-link
                :to="{ name: `${self.usertype}-lesson`, params: { classId: classId,id: props.row.lessonId}, ...{query: {event_id: props.row.event_id,student_id: selectedStudentId}} }"
              >
                {{ props.row.lessonName }}
              </b-link>
            </h4>
            <h5>{{ $t('student-report-module.student-table.total-passed') }} : {{ props.row.totalPass }} / {{ props.row.totalLesson }}</h5>
            <h5
              v-if="props.row.completed_at"
              class="text-success"
            > {{ $t('student-report-module.student-table.completed') }} {{ props.row.completed_at }} </h5>
            <h5
              v-else
              class="text-info"
            > {{ $t('student-report-module.student-table.started') }} {{ props.row.created_at }} </h5>
          </span>
          <span v-else-if="props.column.field === 'cefr'">

            <!-- chart -->
            <vue-apex-charts
              v-if="props.row.cefr_history"
              height="30"
              width="200"
              :options="chartOptions"
              :series="series(props.row.originalIndex)"
            />

          </span>
          <span v-else-if="props.column.field==='points'">{{ props.row.points || 0 }}</span>
          <span v-else-if="props.column.field==='percent'">{{ getRoundOff(props.row.percentage) }}%</span>
          <span v-else-if="props.column.field === 'certificate'">
            <b-button
              variant="flat-success"
              class="btn-icon rounded-circle"
              @click="lessonForCertificate = props.row.lessonId"
            >
              <feather-icon icon="AwardIcon" />
            </b-button>
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            :disabled="isProcessing"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="(value)=>props.pageChanged({currentPage:value})"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </template>
      </vue-good-table>
    </b-col>
    <lesson-detail-model
      :show="!!lessonIdToShowDetail"
      :lesson-id="lessonIdToShowDetail"
      @close="lessonIdToShowDetail=0"
    />
    <CompletedResultView v-if="!!completedParamsInfo"
                         :student-id="selectedStudentId"
                         :params="completedParamsInfo"
                         @close="completedParamsInfo = null"
    />
    <student-certificate v-if="!!lessonForCertificate"
                         :student-id="selectedStudentId"
                         :class-id="classId"
                         :lesson-id="lessonForCertificate"
                         @close="lessonForCertificate = null"
    />
  </b-row>
</template>
<script>
import {
  BCol, BRow, BPagination, BLink, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import { FIRST_LESSON_ACHEIVER_BADGE } from '@/const/badges'
import { isVisible } from '@/utils/visibilitySettings'
import { USER_TYPE_STUDENT } from '@/const/userType'
import LessonDetailModel from '@/views/common/components/LessonDetailModel.vue'
import i18n from '@/libs/i18n'
import StudentCertificate from './StudentCertificate.vue'
import CompletedResultView from './CompletedResultView.vue'

const $trackBgColor = '#EBEBEB'
export default {
  components: {
    BCol,
    BPagination,
    BRow,
    VueGoodTable,
    VueApexCharts,
    BLink,
    LessonDetailModel,
    StudentCertificate,
    BButton,
    CompletedResultView,
  },
  props: {
    rows: {
      type: [Array, Object],
      default: () => {},
    },
    classId: {
      type: Number,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    eventId: {
      type: Number,
      required: false,
    },
    selectedStudentId: {
      type: [Number, Object],
      default: () => null,
    },
    type: {
      type: String,
      default: '',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      completedParamsInfo: null,
      lessonIdToShowDetail: 0,
      pageLength: 10,
      dir: false,
      searchTerm: '',
      self: getUserData(),
      lessonForCertificate: null,
      firstLessonAchieverBadge: FIRST_LESSON_ACHEIVER_BADGE,
      chartOptions: {
        chart: {

          type: 'line',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        grid: {
          borderColor: $trackBgColor,
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: -30,
            bottom: -10,
          },
        },
        stroke: {
          width: 3,
        },
        colors: [$themeColors.info],
        markers: {
          size: 2,
          colors: $themeColors.info,
          strokeColors: $themeColors.info,
          strokeWidth: 2,
          strokeOpacity: 1,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [
            {
              seriesIndex: 0,
              dataPointIndex: 5,
              fillColor: '#ffffff',
              strokeColor: $themeColors.info,
              size: 5,
            },
          ],
          shape: 'circle',
          radius: 2,
          hover: {
            size: 3,
          },
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              fontSize: '0px',
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: {
            show: false,
          },
        },
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          label: i18n.tc('student-report-module.status-table.lessons'),
          field: 'lessonName',
        },
        {
          label: i18n.tc('student-report-module.status-table.score-percentage'),
          field: 'percent',
          width: '130px',
        },
        {
          label: `${i18n.tc('student-report-module.status-table.total')} ${i18n.tc('student-report-module.status-table.points')} (${this.totalPoints})`,
          field: 'points',
        },
        {
          label: i18n.tc('student-report-module.status-table.readability'),
          field: 'cefr',
          html: true,
        },
        ...(this.type === 'completed' ? [
          {
            label: i18n.tc('student-report-module.status-table.certificate'),
            field: 'certificate',
            html: true,
          },
        ] : []),
      ]
    },
    totalPoints() {
      let points = 0
      this.rows.forEach(r => {
        points += r.points
      })
      return points
    },
    isHighLevelUser() {
      return isHighLevelUser || this.self.usertype === 'teacher'
    },
    hideResult() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('lesson_result', schoolSetting, distSetting)
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
  },
  methods: {
    openCompleted(lesson) {
      this.completedParamsInfo = {
        id: lesson.lessonId,
        classId: this.classId,
      }
    },
    series(index) {
      return [
        {
          name: 'Cefr Rate',
          data: this.rows[index].cefr_history,
        },
      ]
    },
    getRoundOff(value) {
      return Math.round(value * 100) / 100
    },
    // goToLink(lesson) {
    //   if (this.isAStudent && this.hideResult) {
    //     return {
    //       name: `${this.self.usertype}-problems`,
    //       params: { classId: this.classId, id: lesson.lessonId },
    //     }
    //   }
    //   return {
    //     name: `${this.self.usertype}-problems`,
    //     params: { classId: this.classId, id: lesson.lessonId, reporting: 'problems' },
    //     ...(this.isHighLevelUser) && { query: { event_id: lesson.event_id, student_id: this.selectedStudentId } },
    //   }
    // },
  },
}

</script>
<style scoped>
.top-badge, .first-badge{
  width:20px;
  height:20px;
}
</style>
